import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import backgroundVideo from './assets/background-video.webm';

const CorsaLandingPage = () => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [activeFeature, setActiveFeature] = useState(0);
  const [signupCount, setSignupCount] = useState(null);
  const controls = useAnimation();

  const features = [
    "Track your rides",
    "Share your adventures",
    "Connect with enthusiasts",
    "Discover new routes"
  ];

  useEffect(() => {
    const timer = setTimeout(() => setShowContent(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (showContent) {
      controls.start(i => ({
        opacity: 1,
        y: 0,
        transition: { delay: i * 0.2 }
      }));
    }
  }, [showContent, controls]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveFeature((prev) => (prev + 1) % features.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [features.length]);

  useEffect(() => {
    // Simulating server-side rendering
    const fetchSignupCount = () => {
      // This would typically be an API call
      const baseCount = 1000;
      const randomIncrement = Math.floor(Math.random() * 500);
      return baseCount + randomIncrement;
    };

    setSignupCount(fetchSignupCount());
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitted email:', email);
    setIsSubmitted(true);
    setEmail('');
    // Increment the signup count
    setSignupCount(prevCount => prevCount + 1);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 100,
      },
    },
  };

  const featureVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 }
  };

  return (
    <div className="relative h-screen w-full overflow-hidden">
      <motion.div
        initial={{ scale: 1.2 }}
        animate={{ scale: 1 }}
        transition={{ duration: 10, ease: "easeOut" }}
        className="absolute inset-0"
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          className="absolute top-0 left-0 w-full h-full object-cover"
        >
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </motion.div>

      <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center p-8">
        <AnimatePresence>
          {showContent && (
            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              className="flex flex-col items-center"
            >
              <motion.h1
                variants={itemVariants}
                className="text-6xl font-bold text-white mb-4"
              >
                CORSA
              </motion.h1>
              <motion.h2
                variants={itemVariants}
                className="text-4xl font-light text-white mb-8"
              >
                Ride. Drive. Share.
              </motion.h2>
              <motion.p
                variants={itemVariants}
                className="text-xl text-white mb-6 text-center max-w-2xl"
              >
                Experience the ultimate social platform for car and bike enthusiasts. Connect, share, and celebrate your passion for the road.
              </motion.p>
              <motion.div
                variants={itemVariants}
                className="h-24 mb-8 flex flex-col items-center justify-center"
              >
                <p className="text-white text-lg mb-2">With Corsa, you can:</p>
                <AnimatePresence mode="wait">
                  <motion.p
                    key={activeFeature}
                    variants={featureVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    className="text-white text-3xl font-bold"
                  >
                    {features[activeFeature]}
                  </motion.p>
                </AnimatePresence>
              </motion.div>
              <motion.div
                variants={itemVariants}
                className="w-full max-w-md"
              >
                <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row items-stretch">
                  <input
                    className="w-full sm:flex-grow px-4 py-3 rounded-t-md sm:rounded-l-md sm:rounded-tr-none focus:outline-none focus:ring-2 focus:ring-white bg-white bg-opacity-20 text-white placeholder-gray-300 mb-2 sm:mb-0"
                    type="email"
                    placeholder="Enter your email"
                    aria-label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <button
                    className="w-full sm:w-auto bg-white hover:bg-gray-200 text-black font-bold py-3 px-6 rounded-b-md sm:rounded-r-md sm:rounded-bl-none transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
                    type="submit"
                  >
                    Join Waitlist
                  </button>
                </form>
              </motion.div>
              {signupCount !== null && (
                <motion.p
                  variants={itemVariants}
                  className="text-white text-sm"
                >
                  {signupCount.toLocaleString()}+ drivers have signed up
                </motion.p>
              )}
              <motion.p
                variants={itemVariants}
                className="text-white text-lg mt-4 font-bold"
              >
                Closed beta. Open to enthusiasts Q1 2025.
              </motion.p>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {isSubmitted && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="mt-4 bg-green-500 text-white p-2 rounded text-sm"
            >
              <p>You're on the list! We'll keep you updated.</p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 2.5, duration: 0.8 }}
        className="absolute top-4 right-4"
      >
        <a 
          href="https://blog.trycorsa.com" 
          className="text-white text-sm hover:text-gray-300 transition-colors duration-300 underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read Our Blog
        </a>
      </motion.div>

      <div className="absolute bottom-2 right-2 text-white text-xs opacity-50">
        Background video courtesy of <a href="https://www.youtube.com/@ShaneChanka" target="_blank" rel="noopener noreferrer" className="underline">Shane Chanka</a>
      </div>
    </div>
  );
};

export default CorsaLandingPage;